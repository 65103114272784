import Mountain from "../../assets/mountain.svg";
import ForestAndWater from "../../assets/merged.png";
import Dino1 from "../../assets/dino_transparent_4.png";
import Dino2 from "../../assets/dino_transparent_5.png";

function Concept() {
  return (
    <>
      <section id="concept" className='mt-3'>
        <div className="container concept--text_container">
          <div className="row">
            <div className="col-12 text-center text--content">
              <h2>Dangerous Dinosaurs is the first ever NFT project to:</h2>
              <p className="text-shadow">
              Take NFTs to the mainstream public with TV commercials in over 100 markets across the USA
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid position-relative concept--image-container">
          <div className="row justify-content-center">
            <img src={Mountain} alt="Mountain" id="mountain" />

            <img
              src={ForestAndWater}
              alt="ForestAndWater"
              id="ForestAndWater"
            />
            <img src={Dino1} alt="Dino1" id="dino1" className="dinos" />
            <img src={Dino2} alt="Dino2" id="dino2" className="dinos" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Concept;
