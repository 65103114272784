import {useRef } from 'react';

function Header() {
  const activeLinkRef = useRef(null);

  const handleLinkClick = (e) =>{
    if(activeLinkRef.current?.classList.contains('active')) {
      activeLinkRef.current.classList.remove('active');
    }
    e.currentTarget.classList.add('active');
    activeLinkRef.current = e.currentTarget;
  }

  return (
    <header>
      <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg justify-content-end">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
                <li className="nav-item">
                  <a onClick={handleLinkClick} ref={activeLinkRef} className="nav-link active" href="#root">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={handleLinkClick} className="nav-link" href="#concept">
                    Concept
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={handleLinkClick} className="nav-link" href="#team">
                    Team
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={handleLinkClick} className="nav-link" href="#faq">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </nav>
      </div>
    </header>
  );
}

export default Header;
