import React from "react";
import ReactDOM from "react-dom";
import { DAppProvider } from "./context";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import App from "./App";

import "./styles/theme.css";

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <DAppProvider>
      <App />
    </DAppProvider>
  </Web3ReactProvider>,
  document.getElementById("root")
);
