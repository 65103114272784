import {useEffect} from "react";
import Header from "./components/Header";
import Home from "./components/sections/Home";
import ConceptAndGoal from "./components/sections/ConceptAndGoal";
import FAQ from './components/sections/FAQ';
import Team from "./components/sections/Team"

function App() {
  const handleScroll = (e) => {
    const header = document.querySelector('header');

    if(window.pageYOffset > 100) {
      header.classList.add('shadow', 'bg-light');
    }else {
      header.classList.remove('shadow', 'bg-light');
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  })

  return (
    <>
      <Header />
      <Home />
      <ConceptAndGoal />
      <Team/>
      <FAQ/>
    </>
  );
}

export default App;
