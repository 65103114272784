import Dino1 from "../../assets/dino_transparent_3.png";
import Dino2 from "../../assets/dino_5.png";

function Team() {
    return(
        <section id="team" className="position-relative">
            <div className="container">
                <div className="row py-5">
                    <h2 className="col-12 text-uppercase text-center">Team</h2>
                    <div className="col-lg-4 col-12">
                        <div className="team--member">
                            <img src={Dino1} alt="Jason Busy - founder"/>
                            <h4>Jason <br/> Founder</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                       <a href="https://www.gsd3d.club/nft" className="d-block w-100 h100 text-dark text-decoration-none">
                        <div className="team--member">
                            <img src={Dino2} alt="GSD club"/>
                                <h4>GSD club <br/> Art and development</h4>
                            </div>
                       </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team