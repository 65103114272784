function FAQ() {
  return (
    <section id="faq" className="text-white">
      <div className="container">
        <div className="row">
          <h3 className="text-center col-12 mb-4">FAQ</h3>
          <ul className="col-12 list-style-none text-center border px-0">
            <li className='p-4 border-bottom'>
              <h4>What are Dangerous Dinos?</h4>
              <p className='m-0'>
                Dangerous Dinos are a collection of 5 different types of
                dinosaurs: T. Rex, Stegosaurus, Brontosaurus, Triceratops and
                Pterodactyl (the flying one). Dangerous Dinos are not looking
                for trouble, but they know it's a jungle out there, so they have
                to be prepared for anything that comes their way. They're mostly
                interested in self defense, unless the object is a tasty meal...
                They are hand drawn, algorithmically generated NFTs trading on
                the Ethereum blockchain.
              </p>
            </li>
            <li className='p-4 border-bottom'>
              <h4>How many Dangerous Dinos are there?</h4>
              <p className='m-0'>At this time, fewer than 2000 Dangerous Dinos are being released.</p>
            </li>
            <li className='p-4 border-bottom'>
              <h4>How much is a Dangerous Dino?</h4>
              <p className='m-0'>
                Each Dangerous Dino will initially be available for 0.1 ETH
              </p>
            </li>
            <li className='p-4 border-bottom'>
              <h4>What is your launch date?</h4>
              <p className='m-0'>June 2022</p>
            </li>
            <li className='p-4 border-bottom'>
              <h4>
                What are the most rare Dangerous Dinos, and how many are there?
              </h4>
              <p className='m-0'>
              Our rare Brontosaurus has either Zombie skin or Lightning bolt in his hand, the mighty T-rex has Atlantis skin or a motivational Go vegan sign, warrior Triceratops is in a Maori skin or with Ice horns, the strongest Stegosaurus either holds an amazing Mace or has the Rainbow skin, and Pterodactyl is in the futuristic Neon skin or tries to fly with a Balloon
              </p>
            </li>
            <li className='p-4 border-bottom'>
              <h4>What will the royalties % be?</h4>
              <p className='m-0'>5%</p>
            </li>
          </ul>
          <h3 className="text-center">
            Be a part of history - literally - and get your Dangerous Dino
            before it goes to the moon!
          </h3>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
