import React, {useContext} from "react";
import { DAppContext } from "../../context";
import Dino1 from "../../assets/dino_transparent_1.png";
import Dino2 from "../../assets/dino_3.png";
import Dino4 from "../../assets/dino_transparent_3.png";
import Dino3 from "../../assets/dino_4.png";
import Dino5 from "../../assets/dino_5.png";
import Dino7 from "../../assets/dino_1.png";

export const urlLink = "http://94.237.99.63:8000/api/";

function Home() {
  const {
    mint,
    connectWallet,
    account = "",
  } = useContext(DAppContext);

  const handleClick = (count) => {
    mint(count);
  } 
  
  return (
    <section className="position-relative mt-0">
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-xl-6 col-12 home--content">
            <h1>Be A Part of History - Literally!</h1>
            <p>
              Dangerous Dinos is a limited edition of 10,000 unique NFTs. We
              believe these will not only sell out, but increase greatly in
              value due to their unique attributes and our ongoing efforts.
            </p>
            {account === "" ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={connectWallet}
                >
                  CONNECT WALLET
                </button>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center claiming-buttons-container">
                <button
                  className="btn btn-primary me-3"
                  onClick={() => handleClick(1)}
                >
                  Claim 1 NFT
                </button>
                <button
                  className="btn btn-primary me-3"
                  onClick={() => handleClick(3)}
                >
                  Claim 3 NFT
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => handleClick(5)}
                >
                  Claim 5 NFTs
                </button>
              </div>
            )}
          </div>
          <div className="col-xl-6 col-12 gallery active">
            <div className="w-100">
              <img src={Dino1} alt="" className="animated--dino_static" />
            </div>
            <div className="w-100">
              <img src={Dino4} alt="" className="animated--dino_static" />
            </div>
            <div className="w-100">
              <img src={Dino5} alt="" className="animated--dino_static" />
            </div>
            <div className="w-100">
              <img src={Dino2} alt="" className="animated--dino_static" />
            </div>
            <img
              src={Dino7}
              alt=""
              className="animated--dino"
              id="dinoAnimated1"
            />
            <img
              src={Dino3}
              alt=""
              className="animated--dino"
              id="dinoAnimated2"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
